// 引入请求方法
import { fun_GetAction } from "@/api";
// 引入地图组件
import MapChart from "./charts/mapChart.vue";
// 默认导出
export default {
  // data
  data: () => {
    return {
      // form对象
      form: {
        cityCount: null, // 省
        provinceCount: null, // 地市
        tranderCount: null // 交易中心
      },
      // 下拉框选中的值
      platformProvince: "420000",
      // 交易中心列表数据
      tableData: [],
      // 交易中心所在省/市
      provinceOptions: []
    };
  },
  // 注册组件
  components: {
    MapChart // 地图组件
  },
  // 模板编译完成
  mounted() {
    // 获取交易中心所在省市
    this.fun_GetProvince();
    // 显示交易中心列表
    this.fun_ChangeProvince("420000");
    // 获取全国交易中心数量
    this.fun_GetProvinceCityCount();
    // 移除token
    if (sessionStorage.getItem("token")) sessionStorage.removeItem("token");
  },
  // 自定义方法
  methods: {
    /**
     * @author 滕威
     * @description 获取交易中心所在省市
     */
    async fun_GetProvince() {
      let res = await fun_GetAction("/bidOpenInfo/getCityData?pid=1");
      const { result, data } = res.data;
      if (result) {
        this.provinceOptions = data;
      }
    },
    /**
     * @author 滕威
     * @description 选择交易中心所在省市
     */
    fun_ChangeProvince(e) {
      let platformProvince = e;
      fun_GetAction(
        "bidOpenInfo/getTradeCenter?platformProvince=" + platformProvince
      ).then(res => {
        const { result, data, msg } = res.data;
        if (result) {
          this.tableData = data;
        } else {
          this.$message.error(msg);
        }
      });
    },
    /**
     * @author 滕威
     * @description 获取全国交易中心数量
     */
    async fun_GetProvinceCityCount() {
      let res = await fun_GetAction("bidOpenInfo/getProvinceCityCount");
      const { result, data, msg } = res.data;
      if (result) {
        this.form = data;
      } else {
        this.$message.error(msg);
      }
    },
    /**
     * @author 滕威
     * @description 进入项目一览
     */
    fun_GetInto(data) {
      this.$router.push({
        path: "/tradingCenterList",
        query: {
          tradeCenterNo: data.platformCode,
          platformName: data.platformName
        }
      });
    }
  }
};
